import axios from "axios";

export default async function SaveResult(ID: string, result: string, repeat?: boolean): Promise<any | false> {
    return await axios.post<any>(`testresult`, {
        ID: ID,
        Result: result,
        repeat
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return false;
    })
}
