import create from 'zustand'
export type AuthStateType = {
    account: null | { email: string, permissions: string, id: number },
    setAccount: (env: null | { email: string, permissions: string, id: number }) => void
}
export type StationStateType = {
    station: null | string;
    setStation: (station: string) => void
}

export const useAuthState = create<AuthStateType>(set => ({
    account: null,
    setAccount: (account) => set({ account }),
}))

export const useStationState = create<StationStateType>(set => ({
    station: null,
    setStation: (station: string) => set({ station }),
}))
