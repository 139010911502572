import axios from "axios";
import {Person} from "../Types/Person";

export default async function SearchPerson(searchText: string): Promise<Person[] | false> {
    return await axios.get<Person[]>(`person/search/${searchText}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return false;
    })
}
