import axios from "axios";

export default async function SaveTest(testID: string, transactionUUid: string, personID: string, testType: number, bundle?: boolean): Promise<any | false> {
    return await axios.post<any>(`test`, {
        ID: testID,
        uuid: transactionUUid,
        personID,
        testType,
        bundle
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return false;
    })
}
