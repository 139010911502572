import axios from "axios";
import moment from "moment";

export default async function ChangeENVRequest(password: string): Promise<any | false> {
    return await axios.post<any>(`changeEnv`, {
        password: password
    }).then((response) => {
        console.log(response)
        return response.data;
    }).catch((err) => {
        console.log(err)
        return false;
    })
}

export async function getStations(): Promise<any | false> {
    return await axios.get<any>(`getStation`).then((response) => {
        console.log(response)
        return response.data;
    }).catch((err) => {
        console.log(err)
        return false;
    })
}
export function changeStation(stationID: number) {
    localStorage.setItem('station', `${stationID}`)
    axios.defaults.headers.common['station'] = stationID;
}

export function getStation() {
    let station = localStorage.getItem('frontend')
    return station !== null ? station : ''
}


const Zentrum = [
    { id: 1, realPrice: 6000, price: 0, label: 'PCR Standard  (ERGEBNIS INNERHALB 24 STUNDEN)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 2, realPrice: 7000, price: 0, label: 'PCR Same-Day (ERGEBNIS AM SELBEN TAG bis 24:00 UHR)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 3, realPrice: 10000, price: 0, label: 'PCR Express (ERGEBNIS INNERHALB 3 STUNDEN)', startDay: 0, endDay: 4, startTime: '08:00', endTime: '18:00' },
    { id: 6, realPrice: 0, price: 0, label: 'Bürgertest', startDay: 0, endDay: 6, startTime: '00:00', endTime: '23:59' },
]
// Bochum AltenBochum 
// 03-33
const AltenBochum = [
    { id: 1, realPrice: 6000, price: 0, label: 'PCR Standard  (ERGEBNIS INNERHALB 24 STUNDEN)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 2, realPrice: 7000, price: 0, label: 'PCR Same-Day (ERGEBNIS AM SELBEN TAG bis 24:00 UHR)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 3, realPrice: 10000, price: 0, label: 'PCR Express (ERGEBNIS INNERHALB 3 STUNDEN)', startDay: 0, endDay: 4, startTime: '08:00', endTime: '18:00' },
    { id: 5, realPrice: 12000, price: 0, label: 'PCR Fast Express (ERGEBNIS INNERHALB 90 min.)', startDay: 0, endDay: 4, startTime: '08:00', endTime: '18:00' },
    { id: 6, realPrice: 0, price: 0, label: 'Bürgertest', startDay: 0, endDay: 6, startTime: '00:00', endTime: '23:59' },
]
// Bochum Lindengemeindehaus
// 03170
const Lindengemeindehaus = [
    { id: 1, realPrice: 6000, price: 0, label: 'PCR Standard  (ERGEBNIS INNERHALB 24 STUNDEN)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 2, realPrice: 7000, price: 0, label: 'PCR Same-Day (ERGEBNIS AM SELBEN TAG bis 24:00 UHR)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 6, realPrice: 0, price: 0, label: 'Bürgertest', startDay: 0, endDay: 6, startTime: '00:00', endTime: '23:59' },
]
// Niederweengern 
// 12222
const Niederwenigern = [
    { id: 1, realPrice: 6000, price: 0, label: 'PCR Standard  (ERGEBNIS INNERHALB 24 STUNDEN)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 2, realPrice: 7000, price: 0, label: 'PCR Same-Day (ERGEBNIS AM SELBEN TAG bis 24:00 UHR)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 6, realPrice: 0, price: 0, label: 'Bürgertest', startDay: 0, endDay: 6, startTime: '00:00', endTime: '23:59' },
]
// Essen Hauptbahnhof 
// 13487
const Hauptbahnhof = [
    { id: 1, realPrice: 6000, price: 0, label: 'PCR Standard  (ERGEBNIS INNERHALB 24 STUNDEN)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 2, realPrice: 7000, price: 0, label: 'PCR Same-Day (ERGEBNIS AM SELBEN TAG bis 24:00 UHR)', startDay: 0, endDay: 6, startTime: '08:00', endTime: '18:00' },
    { id: 6, realPrice: 0, price: 0, label: 'Bürgertest', startDay: 0, endDay: 6, startTime: '00:00', endTime: '23:59' },
]
export const pcrOptions = [
    { options: Zentrum, station: 1 },
    { options: AltenBochum, station: 2 },
    { options: Lindengemeindehaus, station: 3 },
    { options: Niederwenigern, station: 4 },
    { options: Hauptbahnhof, station: 5 },
]

function getTestOptions(station: string) {
    return pcrOptions.find(pcr => `${pcr.station}` === `${station}`)?.options
}

export function getAvailableOptions(date: string, time: string) {
    let station = getStation()
    const testOptions = getTestOptions(station);
    // const filteredOptions = testOptions?.filter((option) => {
    //     const selectedDate = moment(date + ' ' + time, "DD-MM-YYYY HH:mm");

    //     if (option.startDay <= selectedDate.weekday() && option.endDay >= selectedDate.weekday()) {
    //         const startTime = moment(date + ' ' + option.startTime, "DD-MM-YYYY HH:mm");
    //         const endTime = moment(date + ' ' + option.endTime, "DD-MM-YYYY HH:mm");
    //         if (selectedDate.isBetween(startTime, endTime, undefined, '[]')) {
    //             return true;
    //         } else {
    //             return false
    //         }
    //     } else {
    //         return false
    //     }
    // });

    return testOptions;

}