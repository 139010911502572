import {AuthStateType} from "../States/AuthState";

export default function PermissionCheck(permission: string, authState: AuthStateType){

    if(authState.account === null){
        return false;
    }

    const permissions = authState.account.permissions.split(",");
    let found = false;

    permissions.forEach((per) => {
        if(per === permission){
            found = true;
        }
    });

    return found;

}
