import React from "react";
import {Colors} from "../../assets/Colors";

export const SearchResultsHomeStyles: {[key: string]: React.CSSProperties} = {
    backDiv: { backgroundColor: Colors.primary, width: 160, height: 80, borderRadius: 14, justifyContent: 'center', display: "flex", flexDirection: 'column', alignItems: 'center', position: "absolute", right: 0, top: 15},
    backButton: { fontWeight: 'bold', color: '#fff' },
    personHolder: {cursor: "pointer", borderBottom: "2px solid #fff", flexDirection: 'row', height: 100 },
    personText: { height: '97%', width: '100%', margin: '1.5%' },
    height100: { height: '100%',},
    generalDiv: { height: '80%', alignItems: 'center', marginTop: 24 },
    flexDiv: { width: '100%', alignItems: 'flex-end', paddingTop: 100},
    ulHolder: {listStyle: "none", margin: 0, padding: 0}
}
