import create from 'zustand'
export type Envs = 'TEST' | 'RATINGEN' | 'ERKRATH' | 'PEMPELFORT' | 'STEPSTONE' | 'LOCAL';
type EnvironmentStateType = {
    env: Envs,
    internEnabled: boolean,
    setIntern: (internEnabled: boolean) => void;
    setEnv: (env: Envs) => void
}

export const useEnvironment = create<EnvironmentStateType>(set => ({
    env: 'TEST',
    internEnabled: true,
    setIntern: (internEnabled) => set({internEnabled}),
    setEnv: (env) => set({ env })
}))
