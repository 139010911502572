import axios from "axios";

export default async function GetTest(testID: string): Promise<any | false> {
    return await axios.get<any>(`test/${testID}`).then((response) => {
        console.log('', response)
        return response.data;
    }).catch((err) => {
        return false;
    })
}
