import { HomePageStyle } from "../Components/Styles/HomePageStyle";
import TimesSVG from "../assets/times.svg";
import QRReader from "../Components/QRReader";
import React, { useCallback, useEffect, useState } from "react";
import SendLoginRequest from "../Repositories/SendLoginRequest";
import { useAuthState, useStationState } from "../States/AuthState";
import { log } from "util";
import { changeStation } from "../Repositories/ChangeENVRequest";

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const authState = useAuthState();
    const stationState = useStationState();

    useEffect(() => {
        const localItem = localStorage.getItem("account");
        const localStation = localStorage.getItem("station");
        if (localItem !== null) {
            authState.setAccount(JSON.parse(localItem));
        }
        if (localStation !== null) {
            stationState.setStation(localStation)
        }

    }, []);

    const doLogin = useCallback(async () => {

        const loginRequest = await SendLoginRequest(email, password);

        if (loginRequest === false) {
            alert("Please provide correct credentials");
            return;
        }

        if (loginRequest.successfull === false) {
            alert("Please provide correct credentials");
            return;
        }

        authState.setAccount({ permissions: loginRequest.permission, email: email, id: loginRequest.account })
        localStorage.setItem('account', JSON.stringify({ permissions: loginRequest.permission, email: email, id: loginRequest.account }));
        changeStation(loginRequest.station)
    }, [authState, email, password]);

    return (
        <div style={HomePageStyle.mainDiv}>
            <div style={HomePageStyle.inputButtonHolder}>
                <div style={HomePageStyle.inputHolder}>
                    <input value={email} onChange={(event) => setEmail(event.target.value)} placeholder={'E-Mail'} style={HomePageStyle.inputStyle} />
                    <div onClick={() => setEmail('')} style={HomePageStyle.inputXButton}>
                        <img src={TimesSVG} style={HomePageStyle.timesIcon} />
                    </div>
                </div>
                <div style={{ ...HomePageStyle.inputHolder, marginTop: 20 }}>
                    <input type={"password"} value={password} onChange={(event) => setPassword(event.target.value)} placeholder={'Passwort'} style={HomePageStyle.inputStyle} />
                    <div onClick={() => setPassword('')} style={HomePageStyle.inputXButton}>
                        <img src={TimesSVG} style={HomePageStyle.timesIcon} />
                    </div>
                </div>
                <div onClick={() => doLogin()} style={HomePageStyle.searchHolder}>
                    <p style={HomePageStyle.searchText}>Login</p>
                </div>
            </div>
        </div>
    )
}
