import React from "react";

export const HomePageStyle: { [key: string]: React.CSSProperties } = {
    mainDiv: { height: '80%', width: '100%', alignItems: 'center', flexDirection: "column", justifyContent: 'space-around', marginTop: '10%', display: "flex" },
    inputHolder: { flexDirection: 'row', width: 200, display: "flex", position: "relative" },
    inputStyle: { height: 60, borderColor: '#00ffff', borderWidth: 1, flex: 1, position: "relative", },
    inputXButton: { height: 25, width: 25, position: 'absolute', right: 10, justifyContent: 'center', alignItems: 'center', cursor: "pointer" },
    searchHolder: { justifyContent: 'center', cursor: "pointer", alignItems: 'center', display: "flex", height: 60, width: 200, backgroundColor: '#00ffff', marginTop: 22 },
    searchText: { fontSize: 27, color: '#000', fontWeight: 'bold' },
    inputButtonHolder: { alignItems: 'center', marginTop: 22, display: "flex", flexDirection: "column", },
    timesIcon: { width: 25, height: 25, marginTop: 20, },

}
