import React, { useCallback, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import 'react-datepicker/dist/react-datepicker.min.css'
import HomePage from "./Pages/HomePage";
import Termine from "./Pages/Termine";
import { Person } from "./Types/Person";
import Ergebnis from "./Pages/Ergebnis";
import { useAuthState, useStationState } from "./States/AuthState";
import Login from "./Pages/Login";
import axios from "axios";
import { environmentURLS, getEnvURl } from "./States/EnvURLS";
import { Envs, useEnvironment } from "./States/EnvoirementState";
import PermissionCheck from "./Components/PermissionCheck";
import Settings from "./Pages/Settings";
import { getStation as getFrontend } from './Repositories/ChangeENVRequest';

function App() {

    const [activePage, setActivePage] = useState('home');
    const [showBottomBar, setShowBottomBar] = useState(true);
    const [forceOpen, setForceOpen] = useState<Person | undefined>(undefined);
    const authState = useAuthState();
    const envState = useEnvironment();
    const stationState = useStationState();
    const searchParams = new URLSearchParams(document.location.search)
    useEffect(() => {
        let frontendUrl = searchParams.get('frontend')


        const localENV = localStorage.getItem("env");
        const localStation = localStorage.getItem("station");

        let frontend = localStorage.getItem("frontend");
        console.log(frontend)
        console.log(frontendUrl)

        localStorage.setItem("frontend", `${searchParams.get('frontend')}`);

        if (localENV !== null) {
            envState.setEnv(localENV as Envs);
        }

    }, []);



    useEffect(() => {

        if (PermissionCheck('CHECKIN', authState)) {
            setActivePage('home');
        } else {
            // setActivePage('settings');
        }
    }, [authState.account]);


    ///SETTING AXIOS ENV

    useEffect(() => {
        axios.defaults.baseURL = getEnvURl(envState.env);
        axios.defaults.headers.common['frontend'] = getFrontend();
    }, [envState.env]);

    //SETTING AXIOS ENV

    const choosePage = useCallback(() => {
        switch (activePage) {
            case 'home': return <HomePage changePage={(whereTo) => setActivePage(whereTo)} personOpenCallback={() => setForceOpen(undefined)} forceOpenUser={forceOpen} showHideBottomBar={setShowBottomBar} />
            case 'termine': return <Termine changePage={(whereTo) => setActivePage(whereTo)} setForceOpen={(item) => {
                setForceOpen(item);
                setActivePage('home');
            }} showHideBottomBar={setShowBottomBar} />
            case 'ergebnis': return <Ergebnis showHideBottomBar={(status) => setShowBottomBar(status)} changePage={(whereTo) => setActivePage(whereTo)} />
            case 'settings': return <Settings changePage={(whereTo) => setActivePage(whereTo)} />
            default: return <HomePage changePage={(whereTo) => setActivePage(whereTo)} showHideBottomBar={setShowBottomBar} personOpenCallback={() => setForceOpen(undefined)} forceOpenUser={forceOpen} />
        }

    }, [activePage]);

    return (
        <React.Fragment>
            {authState.account !== null ? (
                <React.Fragment>
                    <div className="page">
                        {choosePage()}
                    </div>
                    {showBottomBar && (
                        <div className="bottomBar">
                            <ul>

                                V: {environmentURLS.version}
                                {PermissionCheck('CHECKIN', authState) && (<li onClick={() => setActivePage('home')} className={`${activePage === 'home' && "active"}`}>
                                    Check IN
                                </li>)}
                                {PermissionCheck('RESULT', authState) && (<li onClick={() => setActivePage("ergebnis")} className={`${activePage === 'ergebnis' && "active"}`}>
                                    Ergebnis
                                </li>)}
                            </ul>
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <Login />
            )}


        </React.Fragment>

    );
}

export default App;
