import React, { useCallback, useEffect, useState } from "react";
import { Colors } from "../assets/Colors";
import { TermineStyles } from "../Components/Styles/TermineStyles";
import LoadTermins from "../Repositories/LoadTermins";
import RefreshSVG from '../assets/refresh.svg';
import moment from "moment/moment";
import CloseSVG from '../assets/times.svg';
import BackSVG from '../assets/backRed.svg';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Person } from "../Types/Person";
import SettingsSVG from "../assets/settings.svg";
const SAlert = withReactContent(Swal)
type Props = {
    showHideBottomBar: (status: boolean) => void;
    setForceOpen: (person: Person) => void;
    changePage: (whereTo: string) => void;
}
export default function Termine(props: Props) {

    const [termins, setTermins] = useState<any>([]);
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {

        const interval = setInterval(() => {
            loadTermins().then(undefined);
        }, 20000)

        return () => {
            clearInterval(interval);
        }

    }, []);


    useEffect(() => {

        if (search === '') {
            setSearchResults(termins);
        } else {
            console.log('MIIIAAAUUU', termins)
            setSearchResults(termins.filter((_termin: any) => _termin.Vorname !== null).filter((termin: any) => {
                // FIRST OR LASTNAME CHECK
                return isNameLikeSearch(termin, search)
                // FIRST OR LASTNAME CHECK
            }));
        }
    }, [search, termins]);


    const isNameLikeSearch = useCallback((termin: any, name: string) => {

        let searchedfirstAndLastName = name?.toLocaleLowerCase().split(' ')
        let realFirstName = termin.Vorname?.toLocaleLowerCase().trim()
        let realLastName = termin.Nachname?.toLocaleLowerCase().trim()

        if (name.trim() !== '') {
            if (searchedfirstAndLastName.length > 1) {

                if (realFirstName.includes(searchedfirstAndLastName[0]) && realLastName.includes(searchedfirstAndLastName[1])) {
                    return true;
                }
                else if (realLastName.includes(searchedfirstAndLastName[0]) && realFirstName.includes(searchedfirstAndLastName[1])) {
                    return true;
                }
                else {
                    return false
                }

            } else {
                if (realFirstName.includes(searchedfirstAndLastName[0])) {
                    return true;
                }
                else if (realLastName.includes(searchedfirstAndLastName[0])) {
                    return true;
                }
                else {
                    return false
                }
            }
        }
        return false;

    }, []);

    const loadTermins = useCallback(async () => {
        const date = moment(moment()).format('DD-MM-YYYY')
        const termindata = await LoadTermins(date);
        setTermins(termindata.filter((_termin: any) => _termin.Vorname !== null));
        setSearchResults(termindata);
    }, []);

    useEffect(() => {
        loadTermins().then(undefined);
    }, []);

    const onClickTermin = useCallback((item: any) => {

        SAlert.fire({
            confirmButtonColor: "#618e9c",
            html: (<div>Möchten Sie <p style={{ color: Colors.primary, fontWeight: 'bold', fontSize: 20 }}>{item?.Vorname} {item?.Nachname}</p> einchecken ?</div>),
            confirmButtonText: "Ja",
            cancelButtonText: "Nein",
            showCancelButton: true,
        }).then((isConfirmed) => {
            if (isConfirmed.isConfirmed) {
                props.setForceOpen(item);
            }
        })
    }, []);

    const terminItem = useCallback((item: any) => {
        return (
            <div key={item.ID} onClick={() => { onClickTermin(item) }} style={{ cursor: "pointer", display: "flex", width: '96%',  marginLeft: '2.5%', marginTop: 4, border: '1px solid #00ffff', borderColor: Colors.primary, height: 80, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ flexDirection: 'column', height: '50px', width: 150, display: "flex",justifyContent: 'center', alignItems: 'center', }}>
                    <p style={{ fontWeight: 'bold', color: Colors.primary }}>{item.Time}</p>
                </div>
                <div style={{ display: "flex", flexDirection: 'row', width: 250 }}>
                    <p>{item.Vorname}</p>
                    <p>{item.Nachname}</p>
                </div>
                <div style={{ flexDirection: 'column', width: 250 }}>
                    <p>{item.bundle ? item.bundleName : item.testTypeName}</p>
                </div>
                <div style={{ flexDirection: 'column', display: "flex",  width: 150 }}>
                    <p>{item.additionalData?.length > 0 ? (
                        <>{item.additionalData[0].wert.substring(0, 10)}</>
                    ) : ""}</p>
                </div>
            </div >
        )
    }, []);

    return (
        <div>
            <div onClick={() => props.changePage('home')} style={{ height: 40, width: 90, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#00ffff' }}>
                <img src={BackSVG} width={25} height={25} /> Zurück
            </div>
            <img src={RefreshSVG} onClick={loadTermins} width={35} height={35} style={{ position: "absolute", cursor: "pointer", right: 10, top: 10 }} />
            <div style={TermineStyles.holder}>
                <div style={TermineStyles.tableTop}>
                    <div style={TermineStyles.tableHeadItem}>
                        <p style={TermineStyles.fontBold}>Uhrzeit</p>
                    </div>
                    <div style={TermineStyles.tableHeadItem}>
                        <p style={TermineStyles.fontBold}>Name</p>
                    </div>
                    <div style={TermineStyles.tableHeadItem}>
                        <p style={TermineStyles.fontBold}>Testart</p>
                    </div>
                    <div style={TermineStyles.tableHeadItem}>
                        <p style={TermineStyles.fontBold}>Additional</p>
                    </div>

                </div>
            </div>
            <div style={{ flexDirection: "column", width: '100%', overflowY: "scroll", height: 350, display: "flex", }}>
                {searchResults.map((termin) => {
                    return terminItem(termin);
                })}
            </div>

            <div style={{ display: "flex", justifySelf: "center", justifyContent: "center" }}>
                <div style={{ flexDirection: 'row', width: 200, display: "flex", position: "relative" }}>
                    <input value={search} onChange={(event) => setSearch(event.target.value)} placeholder={'Suche Name in Terminen'} style={{ height: 60, width: 200, borderColor: '#00ffff', borderWidth: 1, display: "flex", flex: 1 }} />
                    <div onClick={() => setSearch('')} style={{ display: "flex", cursor: "pointer", height: 60, width: 60, position: 'absolute', right: 0, justifyContent: 'center', alignItems: 'center' }}>
                        <img src={CloseSVG} width={25} height={25} />
                    </div>
                </div>
            </div>
        </div>
    )

}
