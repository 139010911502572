import axios from "axios";

export default async function SendLoginRequest(email: string, password: string): Promise<any | false> {
    return await axios.post<any>(`aLogin`, {
        Email: email,
        Password: password
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return false;
    })
}
