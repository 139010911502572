import React, { useCallback, useEffect, useState } from "react";
import { Colors } from "../assets/Colors";
import UnfinishedTests from "./UnfinishedTests";
import TestResult from "./TestResult";
import GetTest from "../Repositories/GetTest";
import SearchTest from "./SearchTest";
import { Envs, useEnvironment } from "../States/EnvoirementState";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { HomePageStyle } from "../Components/Styles/HomePageStyle";
import TimesSVG from "../assets/times.svg";
import ChangeENVRequest, { changeStation, getStation, getStations } from "../Repositories/ChangeENVRequest";
import { useAuthState } from "../States/AuthState";
const SAlert = withReactContent(Swal)
type Props = {
    changePage: (whereTo: string) => void;
}

export default function Settings(props: Props) {
    const [stations, setStations] = useState([])
    const [stati, setStati] = useState([])
    const envState = useEnvironment();
    const authState = useAuthState();
    useEffect(() => {
        getStations().then(res => setStations(res)).catch(err => console.log(err))
    }, [])

    const envRequest = useCallback((forEnv: string, error: boolean) => {


        SAlert.fire({
            html: error ? 'Please provide correct passowrd' : '',
            confirmButtonColor: "#618e9c",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Speichern',
            cancelButtonText: 'Abbrechen',
            input: "password",
            inputLabel: "Passwort",

        }).then(async (confirmStatus) => {
            if (confirmStatus.isConfirmed) {
                const send = await ChangeENVRequest(confirmStatus.value);
                if (send) {
                    if (send.status) {
                        envState.setEnv(forEnv as Envs);
                        localStorage.setItem("env", forEnv);
                    } else {
                        envRequest(forEnv, true);
                    }
                } else {
                    envRequest(forEnv, true);
                }
            }
        })

    }, []);

    const getTextColor = useCallback((arg0: string) => {
        return `${arg0}` === getStation() ? '#fff' : Colors.primary
    }, [stati]);

    const getBgColor = useCallback((arg0: string) => {
        return `${arg0}` === getStation() ? Colors.primary : '#fff'
    }, [stati]);

    return (
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", marginTop: 50, width: '100%' }}>
            <p>Standort ändern</p>
            {stations.map((stat: any) => {
                return (
                    <div onClick={() => { changeStation(stat.id); setStati(stat.id) }} style={{ justifyContent: 'center', display: "flex", alignItems: 'center', textAlign:'center',height: 60, width: 200, backgroundColor: getBgColor(stat.id), marginTop: 22 }}>
                        <p style={{ fontSize: 16, color: getTextColor(stat.id), fontWeight: 'bold' }}>{stat.name}</p>
                    </div>
                )
            })
            }
            <div onClick={() => {
                authState.setAccount(null);
                localStorage.removeItem("account");
            }} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 35, height: 60, display: "flex", width: 200, backgroundColor: Colors.primary }}>
                <p style={{ fontSize: 27, color: '#fff', fontWeight: 'bold' }}>LOGOUT</p>
            </div>
        </div>
    )

}
