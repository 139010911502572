import axios from "axios";
import {Person} from "../Types/Person";

export default async function ShowPersonRequest(personID: string): Promise<Person[] | false> {
    return await axios.get<Person[]>(`person/${personID}`).then((response) => {
        console.log(response.data);
        return response.data;
    }).catch((err) => {
        return false;
    })
}
