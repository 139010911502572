import React, { useCallback, useEffect, useState } from "react";
import BackSVG from '../assets/back.svg';
import { Colors } from "../assets/Colors";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SaveResult from "../Repositories/SaveResult";
import DatePicker from 'react-datepicker';
import moment, { Moment } from "moment/moment";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import CLEARBUTTON from '../assets/times.svg';
import { Test } from "../Types/Test";
const SAlert = withReactContent(Swal)

type Props = {
    test: Test;
    goBack: () => void;
}
const minDate = moment().subtract(2, 'd').toDate()
export default function TestResult(props: Props) {

    const [testResult, setTestResult] = useState<string>('');
    const [ctValue, setCTValue] = useState('100');

    const sendSaveRequest = useCallback(async (test: any, result: string) => {

        if ((result !== '' && !Number.isNaN(Number(result))) || result === 'fehlerhaft') {

            const send = await SaveResult(test.ID, result, props.test.Result !== null ? true : undefined);
            setTestResult('');
            props.goBack();
        } else {
            alert('Bitte eine valide Zahl eingeben')
        }
    }, [props]);

    const saveTestResult = useCallback(() => {
        //Sind Sie sich sicher, dass der Test {this.state.result} ist ?
        SAlert.fire({
            title: `Sind Sie sich sicher, dass das Ergebnis den Wert '${!!props.test.resultTypeBool === true ? testResult === '1' ? 'positiv' : 'negativ' : testResult}' hat ?`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Speichern',
            cancelButtonText: 'Abbrechen'
        }).then((confirmStatus) => {
            if (confirmStatus.isConfirmed) {
                if (props.test === null) {
                    sendSaveRequest(props.test, testResult);
                } else {
                    sendSaveRequest(props.test, testResult);
                }
            }
        })
    }, [testResult, sendSaveRequest, props.test, ctValue]);

    const getTextColor = useCallback((result: string) => {
        return testResult === result ? '#000' : '#00ffff'
    }, [testResult]);

    const getBackgroundColor = useCallback((result: string) => {
        return testResult === result ? '#00ffff' : '#fff'
    }, [testResult]);

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", overflowY: "scroll" }}>
            <div onClick={props.goBack} style={{ height: 50, position: 'relative', width: '100%', justifyContent: 'center', top: 0 }}>
                <div style={{ height: '100%', width: 50, display: "flex", backgroundColor: Colors.secondary, justifyContent: 'center', alignItems: 'center', marginLeft: 12 }}>
                    <img src={BackSVG} width={40} height={40} />
                </div>
            </div>
            <p>Name: <strong>{props.test.Vorname} {props.test.Nachname}</strong></p>
            <p> TEL:  <strong>{props.test.Tel}</strong></p>
            <p> Geschlecht:  <strong>{props.test.Geschlecht}</strong></p>
            <p> Alter:  <strong>{2022 - Number(props.test.Jahr)}</strong></p>
            <p> Email:  <strong>{props.test.Email}</strong></p>
            <p style={{ fontWeight: 'bold' }}>  <strong>Test ART: {props.test.testTypeName}</strong></p>
            {props.test.bundleData && (
                <>
                    <p>Bundle {props.test?.bundlename}: {props.test.bundleData.filter((a) => a.Result !== null).length + 1} of {props.test.bundleData.length}</p>
                </>
            )}

            <p> Ergebnis: {props.test.Result?.toUpperCase()}</p>
            {!!props.test.resultTypeBool === false &&
                <div style={{ marginTop: 15, position: "relative" }}>
                    <TextField InputLabelProps={{ color: 'success' }} style={{ backgroundColor: '#fff' }} id="outlined-basic" label="Ergebnis" variant="outlined" value={testResult} onChange={(event) => setTestResult(event.target.value)} type={"number"} />
                    <img src={CLEARBUTTON} width={25} height={25} style={{ position: "absolute", right: 35, top: 15, cursor: "pointer" }} onClick={() => setTestResult('')} />
                </div>}
            <div onClick={() => setTestResult('fehlerhaft')} style={{ cursor: "pointer", display: "flex", justifyContent: 'center', alignItems: 'center', height: 60, width: 200, backgroundColor: getBackgroundColor('fehlerhaft'), marginTop: 22 }}>
                <p style={{ fontSize: 27, color: getTextColor('fehlerhaft'), fontWeight: 'bold' }}>Fehlerhaft</p>
            </div>
            {!!props.test.resultTypeBool === true &&
                <>
                    <div onClick={() => setTestResult('1')} style={{ cursor: "pointer", display: "flex", justifyContent: 'center', alignItems: 'center', height: 60, width: 200, backgroundColor: getBackgroundColor('1'), marginTop: 22 }}>
                        <p style={{ fontSize: 27, color: getTextColor('1'), fontWeight: 'bold' }}>Positiv</p>
                    </div>
                    <div onClick={() => setTestResult('0')} style={{ cursor: "pointer", display: "flex", justifyContent: 'center', alignItems: 'center', height: 60, width: 200, backgroundColor: getBackgroundColor('0'), marginTop: 22 }}>
                        <p style={{ fontSize: 27, color: getTextColor('0'), fontWeight: 'bold' }}>Negativ</p>
                    </div>
                </>
            }
            <div onClick={saveTestResult} style={{ display: "flex", cursor: "pointer", justifyContent: 'center', alignItems: 'center', height: 60, width: 300, backgroundColor: '#00ffff', marginTop: 22 }}>
                <p style={{ fontSize: 27, color: '#000', fontWeight: 'bold' }}>Speichern</p>
            </div>
        </div>
    )

}
