import React, { useCallback, useState } from "react";
import { Colors } from "../assets/Colors";
import UnfinishedTests from "./UnfinishedTests";
import TestResult from "./TestResult";
import GetTest from "../Repositories/GetTest";
import SearchTest from "./SearchTest";
import SettingsSVG from "../assets/settings.svg";
export default function Ergebnis(props: {
    changePage: (whereTo: string) => void;
    showHideBottomBar: (status: boolean) => void;
}) {

    const [activeTab, setActiveTab] = useState('Ergebnis');
    const [activeTest, setActiveTest] = useState(undefined);

    const openTest = useCallback(async (test: any) => {

        const testData = await GetTest(test);

        if (testData === false) {
            alert('Der Test wurde nicht gefunden');
            return;
        }
        if (testData.length < 1) {
            alert('Der Test wurde nicht gefunden');
            return;
        }
        props.showHideBottomBar(false);
        setActiveTest(testData[0]);
    }, []);

    const goBack = useCallback(() => {
        setActiveTest(undefined);
        props.showHideBottomBar(true);
    }, []);

    const renderTab = useCallback(() => {

        switch (activeTab) {
            case 'Ergebnis': return <SearchTest openTest={openTest} />;
            case 'UnfinishedTests': return <UnfinishedTests openTest={openTest} />;
        }


    }, [activeTab]);


    return (
        <div>
            {/* <img onClick={() => props.changePage('settings')} src={SettingsSVG} width={25} height={25} style={{ position: "absolute", top: 15, right: activeTab === 'UnfinishedTests' ? activeTest !== undefined ? 15 : 55 : 15 }} /> */}
            <div style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "row", marginTop: 50, }}>

                <div onClick={() => setActiveTab('Ergebnis')} style={{
                    width: '45%',
                    height: 60,
                    display: "flex",
                    cursor: "pointer",
                    backgroundColor: activeTab !== 'Ergebnis' ? '#fff' : Colors.primary, justifyContent: 'center', alignItems: 'center', borderRadius: 12
                }}>
                    <p style={{ color: '#000' }} >Ergebnis Scan</p>
                </div>

                <div onClick={() => setActiveTab('UnfinishedTests')} style={{
                    cursor: "pointer",
                    width: '45%', height: 60, display: "flex", backgroundColor: activeTab !== 'UnfinishedTests' ? '#fff' : Colors.primary, justifyContent: 'center', alignItems: 'center', borderRadius: 12
                }}>
                    <p style={{ color: '#000' }} >Offene Tests</p>
                </div>

            </div>

            <React.Fragment>
                {activeTest !== undefined ? (
                    <TestResult test={activeTest} goBack={goBack} />
                ) : (
                    <React.Fragment>
                        {renderTab()}
                    </React.Fragment>
                )}
            </React.Fragment>

        </div>

    )

}
