import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Colors } from "../assets/Colors";
import BackSVG from '../assets/backRed.svg';
import SaveSVG from '../assets/save.svg';
import { Person } from "../Types/Person";
import { useEnvironment } from "../States/EnvoirementState";
import { ShowPersonStyles } from "./Styles/ShowPersonStyles";
import moment from "moment/moment";
import GetTermin from "../Repositories/GetTermin";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SaveTest from "../Repositories/SaveTest";
import QRReader from "./QRReader";
import TestResultRequest from "../Repositories/TestResult";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import pencil from '../assets/pencil.svg';

const SAlert = withReactContent(Swal)

type Props = {
    paymentStatus: string
    person: Person,
    updatePaymentStatus: (status: string, payment: boolean) => void;
    payment: boolean;
    onBack: () => void;
}

export default function ShowPerson(props: Props) {

    const envState = useEnvironment();
    const [termin, setTermin] = useState<any | undefined>(undefined);
    const [testID, setTestID] = useState('');
    const [selectedReason, setSelectedReason] = useState('');
    const [editMode, setEditMode] = useState(0);
    const [bundleCounter, setBundleCounter] = useState(0);

    useEffect(() => {
        GetTermin(props.person.TransactionUUID).then((response) => {
            if (response !== false && response !== '') {
                setTermin(response);
            } else {
                console.log("No termin or cannot get");
            }
        })
    }, [props.person]);



    useEffect(() => {

    }, [props.person, editMode]);


    const confirmResult = useCallback(async () => {
        const sendRequest = await TestResultRequest(props.person.TestID, props.person.Result);
        if (sendRequest === false) {
            alert('something is broken');
            return;
        }

        props.onBack();

    }, [props.person]);

    const getTerminText = useCallback(() => {

        return termin !== undefined ? `${termin?.Time} UHR, den ${termin?.Day}` : 'Kein Termin'

    }, [termin]);


    const getAdditional = useCallback(() => {

        if(termin === undefined){
            return (
                <div></div>
            )
        }else{
            if(termin.additionalData !== null && termin.additionalData !== undefined){
                return (
                    <p>
                        {termin.additionalData.fieldName} {termin.additionalData.wert}
                    </p>
                )
            }
            return (
                <div></div>
            )

        }

    }, [termin]);

    const formatTimeStamp = useCallback((timestamp?: string) => {
        if (timestamp) {
            return moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY- HH:mm')
        } else {
            return ''
        }
    }, []);

    const onBackCalled = useCallback(async () => {

        await SAlert.fire({
            confirmButtonColor: "#618e9c",
            title: 'Sie haben noch keinen Test hinzugefügt',
            confirmButtonText: 'Trotzdem schließen',
            cancelButtonText: 'Abbrechen',
            showCancelButton: true
        }).then((confirmResult) => {
            if (confirmResult.isConfirmed) {
                props.onBack();
            }
        })

    }, []);

    const marryTestWithPerson = useCallback(async (testXID?: string) => {
        let tester = testID;
        if (testXID !== undefined) {
            tester = testXID
        }
        if (tester === '') {
            alert('TEST ID ist leer.')
            return
        }
        console.log(props)
        const testSave = await SaveTest(tester, props.person.TransactionUUID, props.person.uuid, props.person.bundle ? props.person.bundleTests[bundleCounter].id : props.person.TransactionType, props.person.bundle);

        if (testSave === false) {
            alert("unexpected error");
            return;
        } else if (testSave.err !== undefined) {
            await SAlert.fire({ title: 'Fehler', html: testSave.err, confirmButtonColor: "#618e9c" });
            return;
        } else {

            if (props.person.bundle && props.person.bundleTests.length - 1 > bundleCounter) {
                setBundleCounter(bundleCounter + 1)
                setTestID('')
            } else {
                props.onBack();
            }
            console.log(props)
        }


    }, [props, testID, selectedReason]);

    function getCurrentBundleTestName() {
        let currentTest = props.person.bundleTests[bundleCounter]
        if (currentTest.isAlreadyBooked) {
            setBundleCounter(bundleCounter + 1)
        }
        currentTest = props.person.bundleTests[bundleCounter]
        return currentTest.name
    }

    const onKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement> | undefined) => {
        if (event === undefined) {
            return;
        }
        const code = event.keyCode || event.which || event.code;
        if (code === 13) { //13 is the enter keycode
            //Do stuff in here
            console.log('WAS GEHT AB1')
            marryTestWithPerson().then(undefined);
        }
    }, [marryTestWithPerson]);

    const onTestIDScan = useCallback((text: string, reason: string) => {
        setTestID(text);
        marryTestWithPerson(text).then(undefined);

    }, [selectedReason, marryTestWithPerson]);
    return (
        <React.Fragment>
            {props.paymentStatus !== 'inProgress' &&
                <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', height: '100%', width: '100%' }}>
                    <div style={ShowPersonStyles.backHolder}>
                        <div style={ShowPersonStyles.backButton} onClick={onBackCalled} >
                            <img src={BackSVG} width={25} height={25} />
                            <p style={ShowPersonStyles.backText}>Zurück</p>
                        </div>
                    </div>
                    <div style={ShowPersonStyles.sendAgainButton}>
                        {envState.internEnabled && props.person.Result &&
                            <div onClick={() => confirmResult()} style={ShowPersonStyles.buttonInnerAgain}>
                                <p style={{ ...ShowPersonStyles.fontBold, ...ShowPersonStyles.fontWhite }}>{'Test erneut übermitteln'}</p>
                            </div>
                        }
                    </div>
                    <p>
                        NAME: {props.person.Vorname} {props.person.Nachname}
                    </p>
                    <p>
                        GEBURTSJAHR: {props.person.Jahr}
                    </p>
                    <p>
                        EMAIL: {props.person.Email}
                    </p>
                    <p>
                        TEL.Nr.: {props.person.Tel}
                    </p>
                    <p>
                        Termin: {getTerminText()}
                    </p>
                    <p>
                        Email: {props.person.Email}
                    </p>

                    <p style={ShowPersonStyles.boldFont}>Test Art: : {props.person.bundle ? props.person.bundleName : props.person.testTypeName}</p>
                    {getAdditional()}
                    {props.person.bundle && <p style={ShowPersonStyles.boldFont}>Test: : {getCurrentBundleTestName()}</p>}
                    {selectedReason !== '' && (<p style={{ cursor: "pointer", textAlign: "center" }} onClick={() => setEditMode(editMode + 1)}>Grund: {selectedReason.slice(0, 10)} <img src={pencil} width={15} height={15} /></p>)}
                    {props.paymentStatus === undefined &&
                        < div style={ShowPersonStyles.paymentHolder}>
                            <div onClick={() => {
                                props.updatePaymentStatus('done', false);
                            }} style={ShowPersonStyles.paymentZahlung}>
                                <p style={ShowPersonStyles.zahlungStyle}>Zahlung</p>
                            </div>
                            <div onClick={() => props.updatePaymentStatus('done', true)} style={ShowPersonStyles.paymentKostenlos}>
                                <p style={ShowPersonStyles.kostenStyle}>kostenlos</p>
                            </div>
                        </div>
                    }

                    <div style={{ marginTop: 16, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>

                        <div style={ShowPersonStyles.inputAndQR}>
                            <div style={ShowPersonStyles.inputHolder}>
                                <input value={testID} onKeyPress={onKeyPress} onChange={(event) => setTestID(event.target.value)} placeholder={'TEST ID'} style={ShowPersonStyles.inputStyle} />
                                <div onClick={() => { console.log('WAS GEHT AB2'); marryTestWithPerson() }} style={ShowPersonStyles.buttonHolder}>
                                    <img src={SaveSVG} width={27} height={27} />
                                    {/*<FontAwesome name="save" size={27} color={'#fff'} />*/}
                                </div>
                            </div>

                        </div>
                        {/* <QRReader updateWith={selectedReason} onRead={(text, reason) => {
                            console.log('REASONMmmm', reason)
                            onTestIDScan(text, reason)

                        }} /> */}
                    </div>
                </div>
            }
        </React.Fragment >
    )

}
