import React, { useCallback } from "react";
import { Person } from "../Types/Person";
import moment from "moment/moment";
import BackSVG from '../assets/back.svg';
import { SearchResultsHomeStyles } from "./Styles/SearchResultsHomeStyles";

type Props = {
    onPressBack: () => void;
    onRequestOpen: (person: Person) => void;
    searchResults: Person[];
}

export function SearchResultsHome(props: Props) {

    return (
        <div>
            <div style={SearchResultsHomeStyles.backDiv} onClick={props.onPressBack} >
                <img src={BackSVG} width={20} height={20} />
                <p style={SearchResultsHomeStyles.backButton}>Zurück</p>
            </div>
            <div style={SearchResultsHomeStyles.flexDiv}>
                <div style={SearchResultsHomeStyles.generalDiv}>
                    <div style={SearchResultsHomeStyles.height100}>
                        <ul style={SearchResultsHomeStyles.ulHolder}>
                            {props.searchResults.map((person, index) => (
                                <li onClick={() => props.onRequestOpen(person)} key={person.ID + index} style={SearchResultsHomeStyles.personHolder}>
                                    <div style={SearchResultsHomeStyles.personText}>
                                        <p>
                                            Name: <strong>{person.Vorname} {person.Nachname}</strong>
                                        </p>
                                        <p>
                                            GB-Jahr: <strong>{person.Jahr}</strong>
                                        </p>
                                        <p>
                                            Testart: <strong>{person.bundle ? person.bundleName : person.testTypeName}</strong>
                                        </p>
                                    </div>
                                </li >
                            ))}
                        </ul>
                    </div>
                </div >
            </div>
        </div>
    )

}
