import React, { useCallback, useState } from "react";
import CloseSVG from '../assets/times.svg';
import QRReader from "../Components/QRReader";

type Props = {
    openTest: (testID: string) => void;
}

export default function SearchTest(props: Props) {

    const [testID, setTestID] = useState('');


    const onKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement> | undefined) => {
        if (event === undefined) {
            return;
        }
        const code = event.keyCode || event.which || event.code;
        if (code === 13) { //13 is the enter keycode
            //Do stuff in here
            props.openTest(testID);
        }
    }, [props, testID]);

    return (
        <div style={{ display: "flex", height: '100%', width: '100%', alignItems: 'center', flexDirection: "column", }}>
            <p> Bitte Geben Sie die Test ID an</p>
            <div style={{ alignItems: 'center', marginTop: 22 }}>
                <div style={{ flexDirection: 'row', display: "flex", position: "relative" }}>
                    <input onKeyPress={onKeyPress} value={testID} onChange={(event) => setTestID(event.target.value)} placeholder={'Test ID'} style={{ height: 70, width: 200, borderColor: '#00ffff', borderWidth: 1 }} />
                    <div onClick={() => setTestID('')} style={{ display: "flex", height: 70, width: 70, position: 'absolute', right: 0, justifyContent: 'center', alignItems: 'center' }}>
                        <img src={CloseSVG} width={20} height={20} />
                    </div>
                </div>
                <div onClick={() => props.openTest(testID)} style={{ justifyContent: 'center', display: "flex", alignItems: 'center', height: 60, width: 200, backgroundColor: '#00ffff', marginTop: 22 }}>
                    <p style={{ fontSize: 27, color: '#000', fontWeight: 'bold' }}>Suchen</p>
                </div>
            </div>
            {/* <QRReader onRead={(text) => {
                setTestID(text);
                props.openTest(text);
            }} /> */}
        </div>
    )

}
