import { env } from "process";

export const environmentURLS = {
    production: false,
    erkrath: 'https://erkrath-backend.ctcr.de/',
    pempelfort: 'https://pempelfort-backend.ctcr.de/',
    // local: 'https://backend.inme-health.de/',
    local: process.env.NODE_ENV === 'development' ? 'http://localhost:8084/' : 'https://backend.inme-health.de/',
    // ratingen: 'http://192.168.178.26:8080/',
    // ratingen: 'http://192.168.178.23:8080/',
    ratingen: 'https://ratingen-backend.ctcr.de/',
    test: 'https://test-backend-b.kfm.digital/',
    stepStone: 'https://stepstone-backend.ctcr.de/',
    version: '1.0'
};

export function getEnvURl(env: string) {
    //@ts-ignore
    // if (environmentURLS[env.toLowerCase()] !== undefined) {
    //     //@ts-ignore
    //     return environmentURLS[env.toLowerCase()];
    // }
    return environmentURLS["local"];
}
