import React, { useCallback, useEffect, useState } from "react";
import UnfinishedRequest from "../Repositories/UnfinishedRequest";
import moment from "moment/moment";
import RefreshSVG from "../assets/refresh.svg";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Colors } from "../assets/Colors";
import { useAuthState } from "../States/AuthState";
import PermissionCheck from "../Components/PermissionCheck";
import { Test } from "../Types/Test";

const SAlert = withReactContent(Swal)

type Props = {
    openTest: (test: any) => void;
}

export default function UnfinishedTests(props: Props) {
    const authState = useAuthState();
    const [tests, setTests] = useState([]);
    const [loadTime, setLoadTime] = useState(moment().format('HH:mm:ss'));

    useEffect(() => {

        loadData().then(undefined);

    }, []);

    const loadData = useCallback(async () => {

        const data = await UnfinishedRequest();

        if (data === false) {
            return;
        }

        setLoadTime(moment().format('HH:mm:ss'));

        setTests(data);

    }, []);

    useEffect(() => {

        const interval = setInterval(() => {
            loadData().then(undefined);
        }, 20000)

        return () => {
            clearInterval(interval);
        }

    }, [loadData]);



    const onClickTest = useCallback((test: any) => {

        SAlert.fire({
            confirmButtonColor: "#618e9c",
            html: (<p>Möchten Sie das Ergebnis für<p style={{ color: Colors.primary, fontWeight: 'bold', fontSize: 20 }}>{test.Vorname} {test.Nachname}</p>übermitteln ?</p>),
            showCancelButton: true,
            cancelButtonText: 'Nein',
            confirmButtonText: 'Ja'
        }).then((confirmStatus) => {
            if (confirmStatus.isConfirmed) {
                props.openTest(test.ID);
            }
        })


    }, []);

    const testItem = useCallback((test: Test) => {
        console.log('TEEEEST', test)
        let time = moment(test.Timestamp, 'YYYY-MM-DD HH:mm:ss')
        let differenceUntilNowInHours = moment().diff(time, 'h')
        let differenceUntilNowInMin = moment().diff(time, 'm')
        let differenceUntilNowInSec = moment().diff(time, 's')
        let differenceUntilNow = new Date(differenceUntilNowInSec * 1000).toISOString().substr(11, 8)
        let limitexceeded = differenceUntilNowInMin >= 15
        return (
            <div onClick={() => onClickTest(test)} style={{ borderBottom: '1px solid #00ffff', width: '100%', paddingBottom: 5, cursor: "pointer", display: "flex", height: 80, justifyContent: 'space-around', alignItems: 'center', marginTop: 4, flexDirection: 'row' }}>
                <div style={{ flexDirection: 'column', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontWeight: 'bold', fontSize: 15 }}>{test.Vorname} </p>
                    <p style={{ fontWeight: 'bold', fontSize: 15 }}>{test.Nachname} </p>
                </div>
                <div style={{ flexDirection: 'column', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontWeight: 'bold', fontSize: 15 }}>{test.ID} </p>
                    <p style={{ fontWeight: 'bold', fontSize: 15 }}>{test.testTypeName?.slice(0, 16)} </p>
                </div>
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p>seit</p>
                    <p style={{ fontWeight: 'bold', color: limitexceeded ? 'red' : 'green', fontSize: 30 }}>{differenceUntilNow} </p>
                    <p>Minuten</p>
                </div>
            </div>
        )

    }, []);


    return (
        <div style={{ width: '100%', alignItems: 'center', overflowY: "hidden", flexDirection: "column", display: "flex", }}>
            <img src={RefreshSVG} onClick={loadData} width={35} height={35} style={{ position: "absolute", cursor: "pointer", right: 10, top: 10 }} />
            <p style={{ marginTop: 40, marginBottom: 15 }}>zuletzt Aktualisiert: {loadTime}</p>
            <p style={{ fontWeight: 'bold', marginTop: 15, }}>{tests.length === 0 ? 'Keine offenen Tests' : ''}</p>
            <div style={{ flexDirection: "column", width: '100%', overflowY: "scroll", height: 350, display: "flex", }}>
                {tests.map((test) => {
                    return testItem(test);
                })}
            </div>

        </div>
    )

}
