import React from "react";
import { Colors } from "../../assets/Colors";



export const ShowPersonStyles: { [key: string]: React.CSSProperties } = {
    backHolder: { height: 50, width: '100%', marginTop: 0, justifyContent: 'flex-end', alignItems: 'flex-end', display: "flex" },
    backButton: { height: 50, justifyContent: 'center', display: "flex", flexDirection: 'row', alignItems: 'center', left: -12 },
    backText: { fontWeight: 'bold', color: Colors.primary, marginLeft: 10, },
    sendAgainButton: { flexDirection: 'row', alignItems: 'center', display: "flex", padding: 4, justifyContent: 'space-around', width: '100%' },
    buttonInnerAgain: { height: 50, width: 100, backgroundColor: '#00ffff', borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: "flex", textAlign: "center", cursor: "pointer" },
    boldFont: { fontWeight: 'bold' },
    paymentHolder: { flexDirection: 'column', justifyContent: 'space-around', width: '100%', display: "flex", marginTop: 12 },
    paymentZahlung: { justifyContent: 'center', alignItems: 'center', height: 60, width: '100%', backgroundColor: '#00ffff' },
    paymentKostenlos: { justifyContent: 'center', alignItems: 'center', height: 60, width: '100%', backgroundColor: '#00ffff', marginTop: 10 },
    kostenStyle: { fontSize: 27, color: '#fff', fontWeight: 'bold', margin: 10, },
    zahlungStyle: { fontSize: 27, color: '#fff', margin: 10, fontWeight: 'bold' },
    fontWhite: { color: "#fff" },
    inputStyle: { height: 56, width: 200, borderColor: '#00ffff', borderWidth: 1 },
    inputHolder: { flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 3, display: "flex", marginRight: 10 },
    buttonHolder: { justifyContent: 'center', alignItems: 'center', height: 60, width: 100, backgroundColor: '#00ffff', display: "flex", marginLeft: 10, cursor: "pointer" },
    inputAndQR: { width: '100%', alignItems: 'center', marginTop: 16, display: "flex", justifyContent: "space-between" }

}
