import axios from "axios";

export default async function LoadTermins(date: string): Promise<any | false> {
    let station = localStorage.getItem('station')
    return await axios.get<any>(`termin/${date}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return false;
    })
}
